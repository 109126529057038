<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [showCommentsButton]="editMode"
  [showStateMachineAuditTrail]="showStateMachineAuditTrail"
  [showHistoryButton]="editMode"
  [showAuditButton]="editMode"
  [showAddCommentButton]="editMode"
  [showShareButton]="editMode"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showRelations]="editMode"
  [showViewSwitch]="editMode"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <div class="flex flex-wrap gap-3">
      <h4 class="mb-0">{{ editMode ? (viewModeService.viewMode | titlecase) : 'Add' }} Issue</h4>
      @if (editMode) {
        <div class="align-self-center">
          <app-badge-item
            [status]="data?.issueStatus"
            [text]="data?.issueStatus"
            (click)="showStateMachine()"
            class="cursor-pointer"
          ></app-badge-item>
        </div>
      }
    </div>
  </ng-container>

  <app-issues-item-form
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [itemId]="itemId"
    [editMode]="editMode"
    [formData]="data"
  ></app-issues-item-form>

  <ng-container page-actions>
    @if (viewMode == 'edit' && showRuleHandler) {
      <app-flat-rule-handler
        [actionMap]="actionsMap"
        [config]="{ requestService: requestService, itemId: itemId }"
      ></app-flat-rule-handler>
    }
  </ng-container>
  @if (editMode) {
    <ng-container below-main-card>
      <app-base-page [showViewSwitch]="editMode">
        <ng-container page-header>
          <div class="flex flex-wrap gap-3">
            <h4 class="mb-0">Remediation Plans</h4>
          </div>
        </ng-container>
        <app-table
          [cols]="cols"
          [tableActions]="[]"
          [actionCol]="null"
          [inputData]="data?.remediations"
          [offlineConfig]="offlineConfig"
          [passIndexWithRowDataToAction]="true"
          [sessionStorageKey]="undefined"
          [selectionMode]="null"
          class="w-full"
        ></app-table>
      </app-base-page>
    </ng-container>
  }
</app-base-page-item>
