<app-base-page
  [breadCrumb]="breadCrumb"
  [pageActions]="[]"
  [pageActionsCommandData]="null"
  [showTreeSwitch]="true"
  [treeIcon]="'fa-folder-tree fas'"
>
  <ng-container page-header>
    <!-- <h4></h4> -->
  </ng-container>
  <app-table
    [cols]="cols"
    [tableActions]="tableActions"
    [actionCol]="actionCol"
    [inputData]="tableData"
    (filtersChanged)="onFilterChange($event)"
    [pageInfo]="tableEvent.pageInfo"
    (onExportData)="loadExportData($event)"
    (onFixRelationAction)="onFixRelations($event)"
    (onExportPackage)="loadExportPackage($event)"
    (onOpenEditDialog)="onOpenEditDialog($event)"
    (onSwapPageMode)="onSwapPageMode($event)"
    [pageActions]="pageActions"
    [pageActionsCommandData]="pageActionsCommandData"
    [sessionStorageKey]="sessionStorageKey"
    [isLoading]="isLoading"
    [settingsUrl]="settingsUrl"
    [moduleKeyword]="moduleKeyword"
    [showBulkOperationButton]="true"
    [bulkOperationUrl]="bulkOperationUrl"
    [showSwapPageMode]="true"
  ></app-table>
</app-base-page>
