<app-base-page
  [breadCrumb]="breadCrumb"
  [pageActions]="pageActions"
  [pageActionsCommandData]="pageActionsCommandData"
  [showTreeSwitch]="true"
  [treeIcon]="'fa-folder-tree fas'"
>
  <ng-container page-header>
    <h4></h4>
    <div class="flex flex-row gap-3 mt-2">
      <app-drop-down-input
        [label]="'Status'"
        [placeholder]="'Select Status'"
        [name]="'status'"
        [optionLabel]="'label'"
        [items]="listOfStatuses"
        [optionValue]="'value'"
        [control]="statusFilter"
        [badgeView]="true"
      ></app-drop-down-input>
      <app-button [action]="this.expandedState ? collapseAllAction : expandAllAction"></app-button>
      <!-- <app-button [action]="collapseAllAction"></app-button> -->
      <app-button [action]="addIssueAction"></app-button>
    </div>
  </ng-container>
  <p-tree
    [value]="formattedNodes"
    [loading]="this.loaderService.loader"
    selectionMode="single"
    [(selection)]="selectedFile"
    class="description-tree"
    styleClass="border-none p-0 w-full"
    [draggableNodes]="true"
    [droppableNodes]="true"
    draggableScope="self"
    droppableScope="self"
    (onNodeDrop)="onDrop($event)"
  >
    <ng-template let-node pTemplate="default">
      <div class="flex flex-row gap-1 tree-button-hover-container">
        <div class="flex-1 flex align-items-center">
          <p class="m-0 text-lg flex gap-1 align-items-center justify-content-center">
            <app-badge-item
              [displayText]="false"
              [showIcon]="true"
              [status]="node?.data?.recordStatus"
              [text]="node?.data?.code"
            >
              <app-code-navigation
                [showRecordStatus]="false"
                [showLabel]="false"
                [data]="node?.data?.code"
                [color]="'inherit'"
              ></app-code-navigation>
            </app-badge-item>

            {{ node?.data?.name }}
          </p>

          <span class="pi pi-arrows-alt tree-button-hover ml-2 cursor-move"></span>
          @if (node?.data?.editMode == 'view') {
            <app-button class="ml-2 tree-button-hover" [action]="editNodeAction" [commandData]="node"></app-button>
          }
          @if (node?.data?.editMode == 'view') {
            <app-button class="ml-2 tree-button-hover" [action]="addNodeAction" [commandData]="node"></app-button>
          }
          <!-- <app-button *ngIf="node?.data?.editMode" class="ml-2" [action]="saveEditNodeAction" [commandData]="node"></app-button>
          <app-button *ngIf="node?.data?.editMode" class="ml-2" [action]="cancelEditNodeAction" [commandData]="node"></app-button> -->
        </div>
        <!-- <div class="flex align-items-center justify-content-between gap-2"> -->
        <!-- <app-user-list-view [data]="node?.data?.creatorName"></app-user-list-view> -->
        <!-- <app-badge-view [data]="node?.data?.type"></app-badge-view> -->
        <!-- <app-badge-view [data]="node?.data?.status"></app-badge-view> -->
        <!-- <app-badge-item [displayText]="false" [showIcon]="true" [status]="node?.data?.recordStatus"
        [text]="node?.data?.code">
        <app-code-navigation [showRecordStatus]="false" [data]="node?.data?.code" [color]="'inherit'"></app-code-navigation>
      </app-badge-item> -->
        <!-- <app-badge-item [displayText]="!!node?.data?.citationType?.name" [status]="node?.data?.recordStatus"
    [text]="node?.data?.citationType?.name"></app-badge-item> -->
        <!-- </div> -->
      </div>
      @if (node?.expanded || node?.data?.editMode != 'view') {
        <ng-container>
          <!-- <app-code-navigation class="text-sm" [data]="node?.data?.issueTypeCode"></app-code-navigation> -->
          <!-- <app-badge-item [displayText]="false" [showIcon]="true" [status]="node?.data?.recordStatus"
    [text]="node?.data?.citationType">
    <app-code-navigation [data]="node?.data?.citationType" [color]="'inherit'"></app-code-navigation>
  </app-badge-item> -->
          @if (node?.data?.description) {
            <div class="mt-1 text-gray-200">
              <app-html-text-view [data]="node?.data?.description"></app-html-text-view>
            </div>
          }
          <!-- <ng-container *ngIf="node?.data?.notes">
  <p-divider class="w-full"></p-divider>
  <div class="w-full font-light">
    <p class="mb-0">Notes:</p>
    <div class="pl-2">
      <app-html-text-view [data]="node?.data?.notes"></app-html-text-view>
    </div>
  </div>
</ng-container> -->
        </ng-container>
        @if (node?.data?.editMode != 'view') {
          <div class="mt-4"></div>
        }
        @if (node?.data?.editMode == 'edit') {
          <app-issues-item-form
            (formSubmit)="onRowEditSave($event, node?.data?.code)"
            (formSubmitSave)="onRowEditSave($event, node?.data?.code)"
            (cancelEvent)="onRowEditCancel($event, node?.data?.code)"
            [formData]="node?.data"
            [showSaveAndClose]="false"
            [showCancelButton]="true"
          ></app-issues-item-form>
        }
        @if (node?.data?.editMode == 'create') {
          <app-issues-item-form
            (formSubmit)="onRowEditSave($event, node?.data?.code)"
            (formSubmitSave)="onRowEditSave($event, node?.data?.code)"
            (cancelEvent)="onRowEditCancel($event, node?.data?.code)"
            [formData]="{ parent: node?.data?.code, code: null }"
            [showSaveAndClose]="false"
            [showCancelButton]="true"
          ></app-issues-item-form>
        }
      }
    </ng-template>
  </p-tree>
</app-base-page>
